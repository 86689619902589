<script lang="ts">
    // Props needed
    export let classes = ''; // Optional classes to be added
    export let error = null; // Session error message
    export let success = null; // Session success message
    export let warning = null; // Session warning message

    // Function to handle alert dismissal
    function dismissAlert(type) {
        if (type === 'error') error = null;''
        if (type === 'success') success = null;
        if (type === 'warning') warning = null;
    }
</script>

{#if error || success || warning}
    <div class="alert-box {classes}">
        {#if error}
            <div class="error-message alert alert-danger alert-success mb-0 text-white">
                <button type="button" class="close" on:click="{() => dismissAlert('error')}">×</button>
                {error}
            </div>
        {/if}

        {#if success}
            <div class="alert alert-dismissible alert-success mb-0 text-white">
                <button type="button" class="close" on:click="{() => dismissAlert('success')}">×</button>
                {success}
            </div>
        {/if}

        {#if warning}
            <div class="alert alert-dismissible alert-warning mb-0 text-white">
                <button type="button" class="close" on:click="{() => dismissAlert('warning')}">×</button>
                {warning}
            </div>
        {/if}
    </div>
{/if}
